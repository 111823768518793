form, .form {
  width: 100%;

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {

    width: 100%;
    border: 0;
    background: none;
    color: var(--input-color);
    padding: 0;
    outline: none;

    /* remove stupid chrome focus styling */
    &:focus {
      outline: none;
      color: var(--input-color);
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    /* remove stupid chrome autofill styling */
    &:-webkit-autofill {
      background-color: transparent !important;
      color: var(--input-color) !important;
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
      -webkit-text-fill-color: var(--input-color) !important;

      &:focus {
        color: var(--input-color) !important;
      }
    }

    &[readonly] { pointer-events: none; }
  }

  [type="checkbox"] {
    &[readonly] { pointer-events: none; }
  }

  .form-row {
    gap: .7rem;
    display: flex;

    & + .form-row {
      margin-top: 1rem;
    }

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  .three-columns {
    display: grid;
    grid-template-columns: repeat(3, 33%);

    @media (max-width: 640px) {
      display: flex;
      flex-direction: column;
    }
  }

  .form-group {

    display: flex;
    flex-direction: column;
    align-content: stretch;

    border-bottom: 2px solid transparent;

    max-width: var(--input-elem-max-width);


    > label > span {
      font-size: var(--label-font-size);
      color: var(--label-color);
      font-weight: 500;
      display: inline-block;

      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.block {
      flex-grow: 99999;
      flex-shrink: 0;
      display: flex;
      flex: 1;
    }

    &.inline {
      display: flex;

      @media (max-width: 640px) {
        max-width: unset;
      }

      > label {
        display: flex;
        gap: 1rem;
        align-items: center;
      }
    }
  }

  .input-wrapper {

    border: 1px solid var(--input-border-color);
    border-radius: var(--input-border-radius);
    background-color: var(--input-background-color);

    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 .6rem;
    outline: 0;
    height: 2.5rem;

    &:has(textarea) {
      height: auto;
    }

    .prepend, .append {
      cursor: default;
      user-select: none;
    }

    .prepend {
      margin-right: .3rem;
    }

    .append {
      margin-left: .3rem;
    }

    /* indicator button */
    .button-indicator {

      border-radius: var(--input-border-radius);
      outline: none;
      background: transparent;
      border: 0;
      flex: 0;
      flex-shrink: 1;

      > svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      > button {
        cursor: pointer;
      }

      &:empty {
        display: none;
      }
    }

    &.select {
      align-items: stretch;
      position: relative;

      > span {
        height: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        pointer-events: none;
        padding-right: .5rem;

        > svg {
          width: var(--icon-size-medium);
        }
      }
    }

    &::after {
      position: absolute;
      content: "";
      height: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      transition: background-color .3s ease-in;
      background-color: transparent;
      opacity: 0;
    }

    &:has(:focus):has([data-state="success"]) {

      .button-indicator { color: var(--color-primary-700); }

      &::after {
        background-color: var(--color-primary-700);
        opacity: 1;
      }
    }

    &:has([data-state="error"]) {

      .button-indicator { color: var(--color-warning-600); }

      &:has(:focus)::after, &::after {
        background-color: var(--color-warning-600);
        opacity: 1;
      }
    }

    &:has([disabled]), &:has([readonly]) {
      color: var(--input-color);
      pointer-events: none;
      .button-indicator { display: none; }

      /* hide error and help messages for disabled and readonly fields */
      & + .help-message, & + .error-message {
        display: none;
      }
    }

    &:has([readonly]) {
      background-color: transparent;
      border: 0;
      padding-left: 0;
    }

    &:has([disabled]) {
      background-color: var(--input-background-color-disabled);
      border-color: var(--input-background-color-disabled);
    }

    &:has(:focus) {

      box-shadow: var(--input-focus-box-shadow);

      &::after {
        background: var(--color-primary-700);
        opacity: 1;
      }

      /* make sure that disabled and readonly fields dont get focus */
      &:has([disabled]), &:has([readonly]) > * {
        pointer-events: none;
      }
    }
  }

  .help-message, .error-message  {

    font-size: .8rem;

    &[data-visible="false"] {
      display: none;
    }
  }

  .help-message {
    color: var(--body-font-color);
  }

  .error-message {
    color: var(--color-warning-700);
  }
}
